import * as React from 'react';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WorldMapPage from './components/WorldMapPage';
import LandingPage from './components/LandingPage';
import LeaderboardPage from './components/LeaderboardPage';
import GamePage from './components/GamePage';
import PageTitle from './components/PageTitle';
import getLPTheme from './getLPTheme';


export interface AppProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}
const zombieTrain = 'ZombieTrain';
export default function App() {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };


  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <PageTitle title={zombieTrain + " - Home"} />
              <LandingPage mode={mode} toggleColorMode={toggleColorMode} />
            </>
            } />
          <Route path="/world-map" element={
            <>
              <PageTitle title={zombieTrain + " - World Map"} />
              <WorldMapPage mode={mode} toggleColorMode={toggleColorMode} />
            </>
          } />
          <Route path="/leaderboard" element={
            <>
              <PageTitle title={zombieTrain + " - Leaderboard"} />
              <LeaderboardPage mode={mode} toggleColorMode={toggleColorMode} />
            </>
          } />
          <Route path="/game" element={
            <>
              <PageTitle title={zombieTrain + " - Game"} />
              <GamePage mode={mode} toggleColorMode={toggleColorMode} />
            </>
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}