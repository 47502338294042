import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

export default function GameWebGL() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "WEBGL_Optim.loader.js",
    dataUrl: "3bea9c277a89a115c66e236056d00b73.data.unityweb",
    frameworkUrl: "cd94e35daa3e40ea72e074d3a0c9d2fd.js.unityweb",
    codeUrl: "505b415b2062c612b6a594fd5f112b65.wasm.unityweb",
  });

  return (
    <Unity
      unityProvider={unityProvider}
      style={{
        height: "48rem",
        width: "24rem",
      }}
    />
  );
}
